import Vue from 'vue'
import Router from 'vue-router'
import VueRouter from 'vue-router'

Vue.use(Router)
const routers = [
  {
    path: '/',
    component: () => import('../App.vue'),
    redirect: '/login',
  },
  // 登录页
  {
    path: '/login',
    component: () => import('../pages/login.vue'),
    name: 'Login',
  },
  // app下载登录
  {
    path: '/registerLogin',
    component: () => import('../pages/registerLogin/registerLogin.vue'),
    name: 'registerLogin',
  },
  // 首页
  { 
    name:'index',path: '/index',component: () => import('../pages/index/index.vue')
  },
  // 用户详情页面
  {
    name:'userSalesDetail',path: '/index/userSalesDetail/:userSalesId',component: ()=> import('../pages/index/userSalesDetail/userSalesDetail.vue')
  },

  // 开户申请单 // 和编辑
  {
    name:'setUserDetail',path: '/index/setUserDetail:userSalesId?',component: ()=> import('../pages/index/setUserDetail/setUserDetail.vue')
  },
  // 成功页面或者失败
  {
    name:'returnStatus',path: '/returnStatus',component: ()=> import('../pages/returnTwoStatus/returnTwoStatus.vue')
  },
  // 信息认证
  {
    name:'inforMationcC',path: '/inforMationcC',component: ()=> import('../pages/inforMationAuthentiCation/inforMationAuthentiCation.vue')
  },
  // 信息认证成功或者失败跳转结果页面
  {
    name:'resultInforCation',path: '/resultInforCation:resultStatus?',component: ()=> import('../pages/inforMationAuthentiCation/resultInforCation.vue')
  },
  // 钱包页面
  // myWalletBeans
  {
    name:'myWalletBeans',path: '/myWalletBeans:userToken?',component: ()=> import('../pages/myGrindBeans/myWalletBeans.vue')
  },
  // 订单详情
  {
    name:'myWalletBeansDetail',path: '/myWalletBeansDetail:objectDetail?:token?',component: ()=> import('../pages/myGrindBeans/myWalletBeansDetail.vue')
  },
  // 银行卡验证页面
  {
    name:'bankCardDeatil',path: '/bankCardDeatil:userToken?',component: ()=> import('../pages/myGrindBeans/bankCardDeatil/bankCardDeatil.vue')
  },
  // 提现余额
  {
    name:'withdrawalBank',path: '/withdrawalBank:balanceDetails?',component: ()=> import('../pages/myGrindBeans/bankCardDeatil/withdrawalBank.vue')
  },
  // 体现成功跳转页面
  {
    name:'sucessWithdrawalChange',path: '/sucessWithdrawalChange',component: ()=> import('../pages/myGrindBeans/bankCardDeatil/sucessWithdrawalChange.vue')
  },
]
//创建一个vue-router实例对象
const router = new VueRouter({
  mode: 'hash', //history 不带#号 hash 带#号
  base: process.env.BASE_URL,
  routes: routers, //所有的路由数组
})
//导出路由对象  使用路由的地方引入即可  一般在入口main.js中使用
export default router
