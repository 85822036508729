// 引入 vue
import Vue from 'vue'
//引入 vuex
import Vuex from 'vuex'
//安装vuex插件
Vue.use(Vuex)
// 创建vuex实例
const store = new Vuex.Store({
  state:{
    userList:{},
    isLoggedIn: false, // 用户登录状态标志位
    USER_DETA_INFORMATION:{
      ACCOUNT_OPEN_APPLICATION:'', // 用户
    } //  用户申请订单汇总
  },
  mutations:{
    /**
    * 代码描述: 获取用户提单所有信息
    * 作者:liushiban
    * 创建时间:
    */
    setWorkloadConfig(state, obj){
      state.USER_DETA_INFORMATION[obj.key] = obj.value
    }
  }
})
 
//导出store对象
export default store;