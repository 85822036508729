// 创建一个名为TimerPlugin的Vue插件
const tools = {
   setTimeMiao(callback, miaoTime) {
      // 设置定时器，在指定时间后执行回调函数
      setTimeout(() => {
        callback()
      }, miaoTime)
    }
}

// 在Vue实例创建之前注册插件
export default function (Vue) {
  //添加全局API
  Vue.prototype.$tools = tools
}